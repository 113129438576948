  div.showCardContainer {
  width: 100%;
  position: relative;
  transition: transform 0.5s ease-in-out, box-shadow 0.3s ease-in-out;
  div.showsSubscriptions {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media all and (max-width:1200px){
        display: none;
      }
    div.items {
      width: 5rem;
      margin-right: 10px;
      background-color: var(--yellow-color);
      padding: 4px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-weight: 600;
      color: var(--white-color);
      margin-bottom: 10px;
      border-radius: 30px;
      &.free {
        background-color: var(--success-color);
      }
      @media all and (max-width:1200px){
        font-size: 8px;
        width: 4rem;
      }
    }
  }
  div.premiumStatus {
    div.premium {
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 8;
      background-color: rgba(8, 25, 43, 0.65);
      // padding: 6px 6px 3px;
      width: 35px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      @media all and (max-width: 1200px) {
        width: 26px;
      }
      svg {
        width: 18px !important;
        height: 18px !important;
        @media all and (max-width: 1200px) {
          width: 12px !important;
          heigth: 12px !important;
        }
      }
    }
    div.free {
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 8;
      width: 50px;
      @media all and (max-width: 1200px) {
        width: 30px;
      }
      img {
        display: block;
        width: 100%;
      }
    }
    div.lock {
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 8;
      width: 20px;
      @media all and (max-width: 1200px) {
        width:15px;
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }
  &:hover {
    z-index: 1;
    transform: translateY(-60px) scale(1.3); 
    div.imageContainer {
      div.extraActions {
        visibility: visible;
        opacity: 1;
        // animation: fadeIn 0.3s ease-in-out;
        animation: opacity 0.3s ease-in-out !important;
      }
      img {
        box-shadow: 0 0 2px rgba(0, 0, 0, .667);
        width: 100%;
        display: block;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        transition: none;
        filter: none;
        z-index: 1;
      }
    }  
    }
  div.imageContainer {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      display: block;
      transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
      z-index: 1;
      &.landscape{
        min-height: 150px;
        background: black;
      }
      // &.portrait{
      //   min-height: 420px;
      //   background: black;
      // }
    }
    div.continueWatchingBar{
      position: absolute;
      bottom: 0;
      height: 6px;
      background-color: var(--white-color);
      width: 100%;
      div.line{
        height: 100%;
        background-color: var(--button-hover);
      }
    }
    div.extraActions {
      visibility: hidden;
      animation: fadeOut 0.2s ease-in-out forwards;
      &.hide{
        display: none;
      }
      &.show{
        display: block;
        div.showCardOverlay{
        cursor: pointer;

        }
      }
      div.showCardOverlay {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: var(--overlay);
        z-index: 8;

      }
      div.playIconContainer {
        position: absolute;
        z-index: 9;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid var(--white-color);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: 0.3s ease-in-out;
        cursor: pointer;
        @media all and (min-width: 320px) {
          width: 30px;
          height: 30px;
          svg {
            width: 13px !important;
            height: 25px !important;
          }
        }
        @media all and (min-width: 320px) {
          width: 40px;
          height: 40px;
        }
        &:hover {
          border-color: var(--button-hover);
          background-color: var(--button-hover);
        }
        svg {
          width: 13px !important;
          height: 13px !important;
          color: var(--white-color);
          transition: 0.3s ease-in-out;
          @media all and (min-width: 320px) {
            width: 13px !important;
            height: 13px !important;
          }
          @media all and (min-width: 480px) {
            width: 25px !important;
            height: 18px !important;
          }
        }
      }
      div.addToList {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 9;
       div.icon{
        svg{
          width: 25px !important;
          height: 25px !important;  
        }
       }
      }
      div.playTrailor{
        position: absolute;
        z-index: 9;
        top: 10px;
        left: 10px;

        svg{
          width: 30px !important;
          height: 30px !important;
          fill: white !important;
        }
      }
    }
  }
  div.metaData {
    margin-top: 10px;
    color: var(--text-color);
    position: absolute;
    bottom: 10px;
    padding-left: 20px;
    width: 100%;
    h1.title {
      color: var(--white-color);
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      transition: 0.3s ease-in-out;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media all and (max-width: 640px) {
        font-size: 14px;
      }
     
    }
    div.datas {
      span{
        font-size: 12px;
        font-weight: 700;
        color: var(--white-color);
        text-transform: uppercase;
        margin-right: 3px;
        &:last-child{
          margin-right: 0;
        }
      }
      span.director{
        font-size: 12px;
        font-weight: 700;
        color: var(--white-color);
        text-transform: uppercase;
        margin-right: 3px;
      }
      

      
    p.description {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      height:30px;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
}
