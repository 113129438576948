div.categoriesContainer{
    border-bottom: 1px solid var(--border-color);
    margin-top: 20px;

    @media all and (max-width:1200px){
        width: 98%;
        margin-left: auto;
        margin-top: 20px;
        .swiper-button-next,.swiper-button-prev{
            display: none;
        }
    }
    @media all and (max-width: 480px) {
        width: 81%;
     margin-left: 0; 
    position: relative;
    left: 20px;
      }
      .swiper-slide {
        z-index: 9;
        &:hover {
            z-index: 99!important;      
        }
      }
    &:hover{
       
        .swiper-button-next{
            &::after{
                opacity:1;
            }
        }
        .swiper-button-prev{
            &::after{
                opacity:1;
            }
        }
    }
    .swiper-button-next{
        &::after{
            
        }
    }
    .swiper-button-prev{
        // &::after{
        //     background: rgb(16, 20, 31);
        //     padding: 6px;
        //     border-radius: 50%;
        //     font-size: 18px;
        //     width: 20px;
        //     height: 20px;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     color: var(--text-color);
        //     opacity: 0;
        //     transition: 0.3s ease-in-out;
        //     @media all and (min-width:320px){
               
        //         font-size: 9px;
        //         width: 10px;
        //         height: 10px;
            
        //     }
        //     @media all and (min-width:980px){
               
        //         font-size: 18px;
        //         width: 20px;
        //         height: 20px;
            
        //      }
        // }
    }

    div.wrapper{
        &:hover{
            div.itemsContainer{

                div.categoryNameContainer{
                    span.seeAll{
                        visibility: visible;
                    }
                }
            }
        }
    }
    div.categoryNameContainerMedium{
        display: none;
        @media all and (max-width:1200px){
            display:block;
            h1{
                font-size: 11px;
                text-transform: uppercase;
                color: var(--text-color);
            }
        }
    }
    
    div.itemsContainer{
        position: relative;
        margin-top: 20px;
        width: 90%;
        margin-left: auto;
        z-index: 999;
        @media all and (max-width:1200px){
            margin-left: 0;
            width: 100%;
            margin-top: 10px;
        }
        div.categoryNameContainer{
           position: absolute;
           z-index: 9;
           left: -122px;
        width: 7rem;

           cursor: pointer;
           @media all and (max-width:1400px){
            left: -112px;
          }
          @media all and (max-width:1200px){
            display: none;
          }
           h1{
            font-size: 12px;
            color: var(--text-color);
            text-transform: uppercase;
            max-width: 99%;
            
           }
           span.seeAll{
            font-size: 11px;
            text-decoration: underline;
            visibility: hidden;
            color: var(--text-color);
           }
        }
        
        div.show{
            width: 14rem;
            @media all and (min-width:320px){
                width: 100%;
            }
            // @media all and (min-width:360px){
            //     width: 11rem;
            // }
            // @media all and (min-width:640px){
            //     width: 13rem;
            // }
            // @media all and (min-width:768px){
            //     width: 13rem;
            // }
            // @media all and (min-width:980px){
            //     width: 13rem;
            // }
        }
    }
}