div.eventDetailsScreen {
    background-color: var(--secondary-bg-color);
    div.topBackgroundContainer {
      position: relative;
      background-repeat: no-repeat;
      width: 100%;
      background-size: 100%;
      background-size: cover;
      padding-bottom: 20px;
      div.contents {
        display: flex;
        justify-content: space-between;
        padding: 0 50px 20px 50px;
      }
      div.bottomGradient {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
        height: 100%;
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: linear-gradient(
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.4) 35%,
          rgba(0, 0, 0, 0.6) 70%,
          rgba(0, 0, 0, 0.8) 100%
        );
      }  
      div.left {     
        padding-top: 220px;
        width: 23%;
        z-index: 9;
        padding-left: 5px;
        @media all and (max-width: 2500px) {
          padding-left: 80px;
        }
        @media all and (max-width: 1600px) {
          padding-left: 5px;
        }
        @media all and (max-width: 1400px) {
          padding-left: 30px;
        }
        @media all and (max-width: 1200px) {
          width: 24%;
        }
        @media all and (max-width: 980px) {
          width: 100%;
          padding-top: 280px;
          padding-left: 20px;
          padding-right: 20px;
        }
        h1.title {
          color: var(--white-color);
          font-size: 28px;
          text-transform: uppercase;
          margin-bottom: 16px;
        }
        div.showMoreInfo {
          // display: flex;
          color: var(--text-color);
          align-items: center;
          border-bottom: 1px solid var(--text-color);
          padding-bottom: 10px;
          margin-bottom: 20px;
          div.icon {
            margin-right: 5px;
            line-height: 0;
            svg {
              width: 16px !important;
              height: 16px !important;
              path {
                fill: var(--white-color) !important;
              }
            }
          }
          div.duration {
            display: flex;
            align-items: center;
            margin-right: 10px;
  
            span {
              font-size: 13px;
              font-weight: 500;
              color: var(--white-color);
              @media all and (max-width: 980px) {
                font-size: 14px;
              }
            }
          }
          div.categoryname{
            display: flex;
            align-items: center;
            margin-right: 10px;
            margin-top: 10px;
            h2{
            color: var(--text-color);
            font-size: 14px;
              span {
                font-size: 15px;
                font-weight: 500;
                color: var(--white-color);
                margin-left: 10px;
                @media all and (max-width: 980px) {
                  font-size: 14px;
                }
              } 
            }
            
          }
        }
        div.actions{
          display: flex;
          align-items: center;
          border-bottom: 1px solid var(--text-color);
          padding-bottom: 10px;
          margin-bottom: 20px;
  
          div.addRating{
            margin-right: 10px;
            cursor: pointer;
            &:hover{
              svg{
                fill: var(--grey-color);
              }
            }
            svg {
              width: 27px !important;
              height: 27px !important;
              fill: var(--white-color);
              transition: 0.3s ease-in-out;
            }
          }
          div.socialLinks{
            display: flex;
            align-items: center;
            div.facebookLink,div.instagramLink{
              margin-right: 10px;
              cursor: pointer;
    
              &:hover{
                svg{
                  color: var(--grey-color);
                }
              }
              svg {
                width: 20px !important;
                height: 20px !important;
                color: var(--white-color);
                transition: 0.3s ease-in-out;
    
              }
            }
          }
          div.userRatings{
            display: flex;
            div.star{
              &.active{
                svg{
                  fill: #fbe725;
                }
              }
              svg {
                width: 23px !important;
                height: 23px !important;
                fill: var(--grey-color);
                transition: 0.3s ease-in-out;
              }
            }
           
          }
          div.favourite{
            margin-right: 10px;
            cursor: pointer;
            &:hover{
              svg{
                fill: var(--grey-color);
              }
            }
            svg {
              width: 23px !important;
              height: 23px !important;
              fill: var(--white-color);
              transition: 0.3s ease-in-out;
  
            }
          }
          div.watchListButton {
            cursor: pointer;
            margin-right: 10px;
            &:hover{
              svg{
                fill: var(--grey-color);
              }
            }
            svg {
              width: 22px !important;
              height: 22px !important;
              transition: 0.3s ease-in-out;
  
            }
          }
  
        }
        div.externalLinks{
          display: flex;
          div.imageContainer{
            margin-right: 10px;
            width: 35px;  
            cursor: pointer;
            &:last-child{
              margin-right: 0;
            }
            img{
              width: 100%;
              display: block;
            }
          }
        }
      }
      div.categoryName {
        background-color: #68abff;
        padding: 10px 6px;
        width: 8rem;
        text-transform: uppercase;
        color: var(--white-color);
        font-weight: 500;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        position: absolute;
        right: 0;
        top: 20px;
        z-index: 9;
        @media all and (max-width: 980px) {
          display: none;
        }
      }
  
      div.top {
        position: absolute;
        right: 0;
        text-align: right;
        padding-top: 60px;
        z-index: 9;
  
        @media all and (max-width: 2500px) {
          right: 100px;
        }
        @media all and (max-width: 1600px) {
          right: 50px;
        }
        @media all and (max-width: 1400px) {
          right: 60px;
        }
        @media all and (max-width: 980px) {
          // padding-top: 20px;     
          // right: 5px;
          padding-top: 75px;
          right: 40px;
        }
        div.ratings {
          display: flex;
          justify-content: right;
          align-items: center;
          padding-right: 20px;
          margin-top: 10px;
          div.icon {
            margin-right: 5px;
            svg {
              width: 18px !important;
              height: 18px !important;
            }
          }
  
          h1.rating {
            font-size: 22px;
            color: var(--white-color);
            @media all and (max-width: 980px) {
              font-size: 23px;
            }
            span.outOf {
              color: var(--text-color);
              font-size: 14px;
              font-weight: 700;
              @media all and (max-width: 980px) {
                font-size: 15px;
              }
            }
          }
        }
        div.trailer {
          padding-right: 5px;
          padding-top: 90px;
          @media all and (max-width: 980px) {
            display: none;
          }
          button.trailerBtn {
            background-color: transparent;
            border: 1px solid var(--white-color);
            width: 5rem;
            font-size: 11px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
            padding: 6px;
            color: var(--white-color);
            margin-left: auto;
            cursor: pointer;
            &:hover {
              border-color: var(--button-hover);
              background-color: var(--button-hover);
            }
            div.icon {
              line-height: 0;
              margin-left: 8px;
              playicon {
              }
            }
          }
        }
  
        span.totalRatings {
          color: var(--text-color);
          font-size: 13px;
          font-weight: 700;
          padding-right: 3px;
          @media all and (max-width: 980px) {
            font-size: 14px;
          }
        }
      }
      div.topLeft{
        position: absolute;
        text-align: right;
        margin-top: 100px;
        z-index: 999;
        cursor: pointer;
      @media all and (max-width: 2500px) {
                margin-left: 80px;
              }
      @media all and (max-width: 1600px) {
                margin-left: 15px;
              }
        svg{
          width: 30px;
          fill: #fff !important;
          stroke: #fff;
          cursor: pointer;
        }
      }
  
      div.right {
        width: 73%;
        padding-top: 270px;
        z-index: 8;
        display: flex;
        @media all and (max-width: 2500px) {
          padding-right: 80px;
        }
        @media all and (max-width: 1600px) {
          padding-right: 5px;
        }
        @media all and (max-width: 1400px) {
          padding-right: 30px;
        }
        @media all and (max-width: 1200px) {
          width: 70%;
        }
        @media all and (max-width: 980px) {
          display: none;
        }
  
        div.description {
          color: var(--white-color);
          width: 50%;
          p.data{
            font-size: 13px;
            @media all and (max-width: 980px) {
              font-size: 14px;
            }
          }
        }
      }
      div.playIconContainer {
        position: absolute;
        width: 5rem;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid var(--white-color);
        transition: 0.3s ease-in-out;
        z-index: 9;
        left: 50%;
        top: 23%;
        transform: translateX(-50%);
        @media all and (max-width: 980px) {
          top: 30%;
        }
        cursor: pointer;
        svg {
          width: 30px !important;
          height: 30px !important;
          color: var(--white-color);
        }
        &:hover {
          background-color: var(--button-hover);
          border-color: var(--button-hover);
        }
      }
      div.subscriptions {
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        z-index: 9;
        padding-left: 5px;
        padding-bottom: 30px;
        @media all and (max-width: 2500px) {
          padding-left: 80px;
        }
        @media all and (max-width: 1600px) {
          padding-left: 50px;
        }
  
        div.items {
          z-index: 9;
          cursor: pointer;
          margin-right: 10px;
          background-color: var(--yellow-color);
          padding: 6px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-weight: 600;
          color: var(--white-color);
          margin-bottom: 20px;
          border-radius: 30px;
          @media all and (max-width: 980px) {
            font-size: 12px;
          }
          &.free {
            background-color: var(--success-color);
          }
        }
      }
      div.shareContainer {
        position: absolute;
        right: 5px;
        z-index: 9;
        bottom: 10px;
        display: flex;
        align-items: center;
        padding-right: 50px;
        cursor: pointer;
        span {
          color: var(--white-color);
          font-size: 14px;
          margin-right: 15px;
          transition: 0.2s ease-in-out;
          &.active {
            transform: translateX(-10px);
          }
        }
        div.icon {
          line-height: 0;
          svg {
            width: 20px !important;
            height: 20px !important;
          }
          .closeIcon {
            stroke: var(--white-color) !important;
            display: block;
            width: 11px !important;
            height: 11px !important;
          }
        }
        div.socialIcons {
          line-height: 0;
          animation: fadeIn 0.9s;
          .facebook {
            margin-right: 15px;
  
            div.icon {
              svg {
                color: var(--white-color);
              }
            }
          }
          .twitter {
            margin-right: 15px;
  
            div.icon {
              svg {
                color: var(--white-color);
              }
            }
          }
          .clipBoard {
            margin-right: 15px;
            display: inline;
            svg {
              fill: var(--white-color);
              width: 20px !important;
              height: 20px !important;
            }
          }
        }
      }
    }
  
    div.responsiveContent {
      padding-bottom: 25px;
      display: none;
      @media all and (max-width: 980px) {
        display: block;
      }
     
      div.description {
        padding: 25px 15px;
        color: var(--text-color);
        p.data {
          font-size: 16px;
        }
      }
    }
  }
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  
  