div.playerShowCard{
    div.imageContainer{
        width: 100%;
        position: relative;
        @media all and (max-width:768px){
            height: 300px;
          }
          @media all and (max-width:640px){
            height: 100%;
          }
        img{
            width: 100%;
            display: block;
            @media all and (max-width:768px){
                height: 300px;
                object-fit: cover;
              }
              @media all and (max-width:640px){
                height: 100%;
                object-fit: contain;
              }
        }
        div.playIconContainer{
            position: absolute;
        transform: translate(-50%, -50%);
        border: 1px solid var(--white-color);
            z-index: 99999;
            top: 50%;
            left: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
            border-radius: 50%;
            transition: 0.5s ease-in-out;
            cursor: pointer;
            svg{
                color: var(--white-color);
                width: 30px !important;
                height: 30px !important;
            }
            @media all and (max-width: 640px) {
                padding: 9px;
                svg{
                    width: 20px !important;
                    height: 20px !important;
                }
              }
            &:hover{
                border: 1px solid var(--button-hover);
                background-color: var(--button-hover);
                
            }
        }
        div.metaData{
            position: absolute;
            bottom: 30px;
            z-index: 999;
            width: 20rem;
            left: 120px;
            @media all and (max-width:1200px){
                left: 42px;
            }
            @media all and (max-width:980px){
                left: 22px;
                
            }
            span.categoryName{
                font-weight: 700;
                font-size: 14px;
                color: var(--white-color);
                text-transform: uppercase;
                display: block;
            }
            h1.showName{
                font-size: 45px;
                font-weight: 700;
                color: var(--white-color);
                text-transform: uppercase;
                @media all and (max-width:980px){
                    font-size: 35px;
                    
                }
                @media all and (max-width: 640px) {
                   font-size: 25px;
                  }
            }
            span.director{
                font-weight: 700;
                font-size: 13px;
                color: var(--white-color);
                text-transform: uppercase;
                margin-right: 3px;
            }
            span.country{
                font-weight: 400;
                font-size: 13px;
                color: var(--white-color);
                text-transform: uppercase;
                margin-right: 3px;
            }
            span.year{
                font-weight: 400;
                font-size: 13px;
                color: var(--white-color);
                text-transform: uppercase;
            }
            p.description{
                color: var(--white-color);
                font-size: 13px;
            }
        }
        div.bottomGradient {
            position: absolute;
            bottom: 0;
            width: 100%;
            z-index: 1;
            height: 100%;
            background-position: top;
            background-size: cover;
            background-repeat: no-repeat;
            background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
          }
    }
}