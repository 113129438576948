div.featured {
//   background-repeat: no-repeat;
  overflow: hidden;
  width: 100%;
//   background-size: cover;
  position: relative;
  border-bottom: 1px solid var(--border-color);
  
  @media all and (max-width:768px){
    height: 300px;
  }
  @media all and (max-width:640px){
    height: 100%;
  }
  .swiper{
    height: 100%;
  } 
  .swiper-pagination-bullet{
    background-color: var(--heading-color) !important;

    opacity: 0.8 !important;
  } 
  .swiper-pagination-bullet-active{
    background-color: var(--grey-color) !important;

    opacity: 1 !important;

  }
  div.bgImageContainer{
    width: 100%;
    display: block;
    height: 100%;
    img{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media all and (max-width:768px){
            object-fit: cover;
         }
    }
  }
  div.bottomGradient {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
  }
  
  div.gallerySection{
    position: absolute;
    z-index: 9;
    right: 0;
    bottom: 42px;
    width: 22rem;
    @media all and (max-width:1400px){
        width:  18.5rem;
    }
    @media all and (max-width:1200px){
        width: 21rem;
    }
    @media all and (max-width:980px){
        display:none;
    }
    .swiper-slide{
        width: 108px !important;
        @media all and (max-width:1400px){
            width: 89px !important;
        }
        @media all and (max-width:1200px){
            width: 103px !important;
        }
    }
    .swiper{
        overflow: hidden;
    }
    .swiper-slide-visible{
        opacity: 0.4;
    }
    .swiper-slide-thumb-active{
        opacity: 1;
    }
    div.imageContainer{
        width: 100%;
        img{
            width: 100%;
            display: block;
            border-radius: 6px;
        }
    }
  }
  div.contents{
    position: absolute;
    bottom: 30px;
    width: 92%;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    span.line{
        height: 1px;
        background-color: var(--border-color);
        width: 30%;
        display: block;
        margin-bottom: 20px;
        @media all and (max-width:768px){
            display: none;
          }
    }
    h1.title{
        font-size: 55px;
        color: var(--white-color);
        margin-bottom: 30px;
        animation: slideUp 0.5s ease-in-out;
        @media all and (max-width:768px){
           font-size: 36px;
           margin-bottom: 0;
          }
    }
    div.containers{
        display: flex;
        align-items: center;
        div.left{
            width: 8%;
            margin-right: 30px;
            @media all and (max-width:1200px){
                width: 12%;
             }
             @media all and (max-width:980px){
                width: 14%;
             }
             @media all and (max-width:768px){
                display: none;
             }
            div.activeThumb{
                width: 100%;
                div.imageContainer{
                    width: 100%;
                    img{
                        width: 100%;
                        display: block;
                        border-radius: 8px;
                    }
                }
              }
        }
        div.right{
            width: 78%;
            div.metaData{
                padding: 35px 0;
                margin: 0 auto;
                @media all and (max-width:768px){
                    padding: 20px 0;
                }
                div.datas{
                    animation: slideUp 0.5s ease-in-out;
        
                    span{
                        color: var(--grey-color);
                        font-size: 18px;
                        @media all and (max-width:768px){
                            font-size: 15px;
                        }
                        &.year{
                            margin-right: 6px;
                            
                        }
                        &.duration{
                            margin-right: 6px;
                            &::before{
                                content: "·";
                                margin-right: 6px;
                            }
                        }
                        &.certificate{
                            
                            display: inline-block;
                            padding: 1px 6px;
                            background-color: rgba(150,153,163,.32);
                            font-size: 12px;
                            font-weight: 700;
                            color: var(--white-color);
                            border-radius: 4px;
                        }
                    }
                    div.genres{
                        margin-top: 6px;
                        span{
                            &::before{
                                content: "·";
                                margin-right: 6px;
                            }
                            margin-right: 6px;
                            &:first-child{
                                &::before{
                                    display: none;
                                }
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                    
                }
                div.buttonContainer{
                    margin-top: 30px;
                    background: var(--text-hover);
                    padding: 12px 24px;
                    width: 13rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 8px;
                    color: var(--white-color);
                    font-weight: 700;
                    cursor: pointer;
                    transition: 0.3s ease-in-out;
                    &.no-flex{
                        justify-content: left;
                    }
                    svg{
                        margin-right: 10px;
                    }
                    &:hover{
                        background:var(--button-hover);
                    }
                    span{
                        color: var(--white-color);
                    font-weight: 700;
                    font-size: 15px;
                    &.free{
                        display: inline-block;
                        padding: 1px 6px;
                        color: #000;
                        background-color: var(--white-color);
                        border-radius: 30px;
                        font-size: 10px;
                    }
        
                    }
                }
            }
        }
    }
  }
  @keyframes slideUp{
    0%{
        transform: translateY(0);
        opacity: 0;
    }
    50%{
        transform: translateY(15px);

    }
    100%{
        transform: translateY(0);
        opacity: 1;

    }
  }
  div.addToWatchList{
    position: absolute;
    top: 30px;
    right:30px;
    z-index: 9;
   
    svg{
      width: 25px !important;
      height: 25px !important;
     
    }     
  }
}
