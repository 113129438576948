div.myList{
    padding: 50px 0 0;
    div.top{
        background-color: var(--white-color);
        padding: 20px 0;
        h1.heading{
            font-size: 30px;
            color: var(--heading-color);
            text-align: center;
            @media all and (max-width:640px){
                font-size: 25px;
            }
        }
        p.desc{
            font-size: 18px;
            color: var(--text-color);
            @media all and (max-width:640px){
                font-size: 14px;
            }
        }
    }
    div.bottom{
        background-color: var(--secondary-bg-color);
        padding: 20px 5px 0;
        text-align: center;
        
        div.noContentInfo{
            width: 20rem;
            margin: 0 auto;
            padding: 50px 5px 200px;
            @media all and (max-width:640px){
                width: 100%;
            }
            h4.heading{
                font-size: 15px;
                color: var(--heading-color);
                margin-bottom: 10px;
            }
            p{
                font-size: 14px;
                color: var(--heading-color);
                line-height: 1.8em;
                svg{
                    width: 12px !important;
                    height: 12px !important;
                    fill: var(--text-color);
                }
            }
        }
        div.itemsContainer{
            display: flex;
            flex-wrap: wrap;
            text-align: left;
            div.items{
                &.rowCount3{
                    width: 32.5%;
                    margin-right: 13px;
                    margin-bottom: 30px;
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                    @media all and (max-width:1200px){
                        width: 32%;
                    }
                }
                &.rowCount4{
                    width: 24%;
                    margin-right: 13px;
                    margin-bottom: 30px;
                    &:nth-child(4n){
                        margin-right: 0;
                    }
                    @media all and (max-width:1200px){
                        width: 23.8%;
                    }
                }
                &.rowCount5{
                    width: 19%;
                    margin-right: 13px;
                    margin-bottom: 30px;
                    &:nth-child(5n){
                        margin-right: 0;
                    }
                    @media all and (max-width:1200px){
                        width: 18.5%;
                    }
                    div.showCardContainer{
                      div.metaData{
                        h1.title{
                          font-size: 14px;
                        }
                      }
                    }
                }
                &.rowCount6{
                    width: 15.8%;
                    margin-right: 13px;
                    margin-bottom: 30px;
                    &:nth-child(6n){
                        margin-right: 0;
                    }
                    @media all and (max-width:1400px){
                        width: 15.5%;
                    }
                    @media all and (max-width:1200px){
                        width: 15%;
                    }
                    div.showCardContainer{
                      div.metaData{
                        h1.title{
                          font-size: 14px;
                        }
                      }
                    }
                }
                &.portrait{
                  
                    // @media all and (max-width:1400px){
                    //     width: 15.6%;
                    // }
                    // @media all and (max-width:1200px){
                    //     width: 18.8%;
                    //     &:nth-child(5n){
                    //         margin-right: 0;
                    //     }
                    //    &:nth-child(6n){
                    //     margin-right: 13px;
                    //    }
                    // }
                    @media all and (max-width:980px){
                        width:23.5%;
                        margin-right: 13px;
                        margin-bottom: 40px;
                        &:nth-child(4n){
                            margin-right: 0;
                        }
                       &:nth-child(5n){
                        margin-right: 13px;
                       }
                    }
                    @media all and (max-width:768px){
                        width:23%;
                        &:nth-child(4n){
                            margin-right: 0;
                        }
                       &:nth-child(5n){
                        margin-right: 13px;
                       }
                    }
                    @media all and (max-width:640px){
                        width:31%;
                        
                       &:nth-child(4n){
                        margin-right: 13px;
                       }
                       &:nth-child(3n){
                        margin-right: 0;
                    }
                    }
                    @media all and (max-width:480px){
                        width:47%;
                        
                       &:nth-child(3n){
                        margin-right: 13px;
                       }
                       &:nth-child(2n){
                        margin-right: 0;
                    }
                    }
                }
                &.landscape{
                    // width: 32.8%;
               
                // &:nth-child(3n){
                //     margin-right: 0;
                // }
                // @media all and (max-width:1400px){
                //     width: 32.5%;
                // }
                // @media all and (max-width:1200px){
                //     width:32%;
                // //     &:nth-child(4n){
                // //         margin-right: 0;
                // //     }
                // //    &:nth-child(5n){
                // //     margin-right: 13px;
                // //    }
                // }
                @media all and (max-width:980px){
                    width:32%;
                    margin-right: 13px;
                margin-bottom: 40px;
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                    
                }
                @media all and (max-width:768px){
                    width:47.6%;
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                    &:nth-child(3n){
                        margin-right: 13px;
                    }
                }
                @media all and (max-width:640px){
                    width:48%;
                    
                   &:nth-child(3n){
                    margin-right: 13px;
                   }
                   &:nth-child(2n){
                    margin-right: 0;
                }
                }
                @media all and (max-width:480px){
                    width:47%;
                    
                   &:nth-child(3n){
                    margin-right: 13px;
                   }
                   &:nth-child(2n){
                    margin-right: 0;
                }
                }
                }
  
            }
        }
    }
}