div.landingScreen {
  padding: 100px 0 0;
  h1.heading {
    color: var(--white-color);
    margin-bottom: 10px;
    font-size: 45px;
    font-weight: 500;
	@media all and (max-width:1400px){
		font-size: 38px;
	}
	@media all and (max-width:1200px){
		font-size: 31px;
	}
	@media all and (max-width:768px){
		font-size: 27px;
	}
  }
  p.description {
    color: var(--white-color);
    margin-bottom: 10px;
    font-size: 23px;
	@media all and (max-width:1400px){
		font-size: 19px;
	}
	@media all and (max-width:1200px){
		font-size: 17px;
	}
  }

  div.buttonContainer {
    display: flex;
    padding: 12px 20px;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    justify-content: center;
    position: relative;
    width: 15rem;
    @media all and (max-width: 768px) {
      margin: 20px 0 0;
	  width: 13rem;
    }
    @media all and (max-width: 640px) {
      width: 12rem;
	  margin: 0 auto;
    }
    @media all and (max-width: 480px) {
      width: 15rem;
    }
    &:hover {
      div.background {
        transform: scale(1.0476190476, 1.0357142857);
      }
    }
    div.background {
      background: var(--text-hover);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 4px;
      transition: 0.1s ease-in-out;
      z-index: 1;
    }
    button {
      background: transparent;
      font-size: 15px;
      color: var(--white-color);
      font-weight: 600;
      position: relative;
      z-index: 2;
      cursor: pointer;
	  @media all and (max-width: 768px) {
		font-size: 13px;
	  }
    }
  }

  div.spotlight {
    display: flex;
    align-items: center;
    background-color: var(--black-color);
    height: 500px;
	@media all and (max-width: 640px) {
		flex-direction: column-reverse;
		height: 269px;
	}
    &.rightType {
      position: relative;
      div.left {
        width: 45%;
        position: absolute;
        left: 7%;
        z-index: 3;
        height: 100%;
        padding-top: 120px;
        background: linear-gradient(to right, #000 80%, transparent 100%);
		@media all and (max-width: 640px) {
			background: linear-gradient(to top, #000 96%, transparent 100%);
			
			left: unset;
			width: 100%;
			text-align: center;
			padding-top: 40px;
			bottom: -44%;
		}
      }

      div.right {
        width: 60%;
        position: absolute;
        right: 0;
        height: 100%;
        z-index: 1;
		@media all and (max-width: 640px) {
			left: unset;
			top: 0;
			width: 100%;
			height: 52%;
		}
        div.imageContainer {
          width: 100%;
          height: 100%;
          img {
            display: block;
            width: 100%;
            height: 100%;
			object-fit: cover;
          }
        }
      }
    }
  }

  div.watchNowSection {
    display: flex;
    color: var(--black-color);
    align-items: center;
    background-color: var(--black-color);
    position: relative;
    height: 500px;
	@media all and (max-width: 640px) {
		flex-direction: column;
		height: 269px;
		margin-top: 99px;
	}
    div.left {
      width: 60%;
      height: 100%;
	  position: absolute;
	  @media all and (max-width: 640px) {
		left: unset;
		top: 0;
		width: 100%;
		height: 55%;
	}
      div.imageContainer {
        height: 100%;

        width: 100%;
        img {
          height: 100%;
		  object-fit: cover;
          display: block;
          width: 100%;
        }
      }
    }

    div.right {
      width: 50%;
      position: absolute;
      right: 0;
      z-index: 3;
      height: 100%;
      padding-top: 120px;
      background: linear-gradient(to left, #000 87%, transparent 100%);
	  @media all and (max-width: 640px) {
		background: linear-gradient(to top, #000 96%, transparent 100%);
		
		right: unset;
		width: 100%;
		text-align: center;
		padding-top: 40px;
		bottom: -47%;
	}
      h1,
      p,
      div.buttonContainer {
        margin-left: 80px;
		@media all and (max-width:640px){
			margin-left: 0;
		}
      }
	  div.buttonContainer{
		@media all and (max-width:640px){
			margin: 0 auto;
		}
	  }
      p {
        font-size: 20px;
		@media all and (max-width:1400px){
			font-size: 19px;
		}
		@media all and (max-width:1200px){
			font-size: 17px;
		}
      }
    }
  }
  div.other {
    &.rightType {
      display: flex;
      align-items: center;
      background-color: var(--black-color);
      position: relative;
      height: 500px;
	  @media all and (max-width: 640px) {
		flex-direction: column-reverse;
		height: 269px;
	}
      div.left {
        width: 45%;
        position: absolute;
        left: 7%;
        z-index: 3;
        height: 100%;
        padding-top: 120px;
        background: linear-gradient(to right, #000 80%, transparent 100%);
		@media all and (max-width: 640px) {
			background: linear-gradient(to top, #000 96%, transparent 100%);
			
			left: unset;
			width: 100%;
			text-align: center;
			padding-top: 40px;
			bottom: -91%;
		}
	}

      div.right {
        width: 60%;
        position: absolute;
        right: 0;
        height: 100%;
        z-index: 1;
		@media all and (max-width: 640px) {
			left: unset;
			top: 129px;
			width: 100%;
			height: 52%;
		}
        div.imageContainer {
          width: 100%;
          height: 100%;
          img {
            display: block;
            width: 100%;
            height: 100%;
			object-fit: cover;

          }
        }
      }
    }
  }

  div.device {
    display: flex;
    background: var(--white-color);
    padding-top: 30px;
    justify-content: space-between;
    padding-bottom: 100px;
	@media all and (max-width: 640px) {
		margin-top: 245px;
		flex-direction: column;
	}
    div.left {
      width: 40%;
      padding-left: 60px;
	  @media all and (max-width: 768px) {
		padding-left: 30px;
	}
	@media all and (max-width: 640px) {
		width: 100%;
		margin-bottom: 30px;
	}
      h1.heading {
        color: var(--black-color);
      }

      p.description {
        color: var(--text-color);
      }
    }

    div.right {
      width: 40%;
	  @media all and (max-width: 1980px) {
		width: 43%;
	}
	@media all and (max-width: 1600px) {
		width: 46%;
	}
	@media all and (max-width: 1400px) {
		width: 55%;
	}
	@media all and (max-width: 1200px) {
		width: 46%;
	}
	@media all and (max-width: 980px) {
		width: 55%;
	}
	@media all and (max-width: 768px) {
		width: 56%;
	}
	@media all and (max-width: 640px) {
		width: 91%;
		margin: 0 auto;
	}
      ul.devices {
        display: flex;
        flex-wrap: wrap;
		
        li {
          cursor: pointer;
          width: 17rem;
          height: 10rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 6px;
          margin-bottom: 6px;
          background: rgb(54, 124, 160);
          background: linear-gradient(
            90deg,
            rgba(54, 124, 160, 1) 0%,
            rgba(14, 64, 115, 1) 35%,
            rgba(0, 41, 97, 1) 100%
          );
          transition: 0.2s ease-in-out;
		  &:nth-child(3n) {
            margin-right: 0;
          }
		  @media all and (max-width: 2200px) {
			  width: 15rem;
		  }
		  @media all and (max-width: 1980px) {
			  width: 13rem;
			  height: 9rem;
		  }
		  @media all and (max-width:1200px){
			
			  
			  &:nth-child(3n) {
				margin-right: 6px;
			  }
			  &:nth-child(2n){
				margin-right: 0;
			  }
		  }
		  @media all and (max-width: 980px) {
			width: 12rem;
			height: 7rem;
		}
		@media all and (max-width: 768px) {
			width: 10rem;
		}
		@media all and (max-width: 640px) {
			width: 13rem;
		}
		@media all and (max-width: 480px) {
			width: 10rem;
		}
		@media all and (max-width: 360px) {
			width: 100%;
			margin-right: 0;
			&:nth-child(3n) {
				margin-right: 0;
			  }
		}
          
		 
          &:hover {
            box-shadow: 0px 1px 6px 4px #333;
          }
          span {
            color: var(--white-color);
            font-weight: 700;
            font-size: 30px;
			@media all and (max-width: 980px) {
				font-size: 24px;
			}
          }
        }
      }
      
      
    }
  }
}
