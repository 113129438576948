div.trailerModal{

    div.overlayModal{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    div.head{
        position: fixed;
        z-index: 9999;
        color: var(--white-color);
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        top: 20px;
        div.left{
            h4.heading{
                font-size: 13px;
            }
            h1.title{
                font-size: 22px;
                text-transform: uppercase;
            }
            span.director{
                font-weight: 700;
                font-size: 14px;
                margin-right: 5px;
            }
            span.year{
                font-weight: normal;
                font-size: 13px;
            }
        }
        div.right{
            div.closeIcon{
                cursor: pointer;
                svg{
                    stroke: var(--white-color) !important;
                }
            }
        }
    }
    div.trailerPlayer{
        z-index: 999;
        text-align: center;
        position: fixed;
        left: 50%;
        // right: 0;
        top: 55%;
        // bottom: 0;
        transform: translate(-50%,-50%);
        width: 50%;
        margin: 0 auto;
        @media all and (max-width:1400px){
            top: 50%;
            width:60%;
        }
        @media all and (max-width:1200px){
            top: 50%;
            width: 60%;
        }
        @media all and (max-width:980px){
            top: 45%;
            width: 70%;
        }
        @media all and (max-width:480px){
            width: 95%;
        }

        video{
            width: 100%;
            display: block;
        }
        
       
    }
}