div.magazineCardContainer{
    width: 100%;
    cursor: pointer;
    &.noPointer{
      cursor: default;
    }
    position: relative;
    div.showMoreOverlay{
      display: none;
      
      
    }
    
    &.cardHover{
    &:hover{
      .square{  
        animation: fadeIn 0.2s ease-in-out ;
        background-color: var(--overlay);
        div.metaData{
          display: block;
          visibility: visible !important;
          animation: fadeIn 0.1s ease-in-out;
        }
      }
    }
  }
    .square {
      width: 100%;
      transition: transform 500ms ease-out, border-radius 200ms ease-out;
      &.hide{
        div.metaData{
          display: none;
          @media all and (max-width:980px){
            display: block;
          }
        }
      }
        div.metaData{
          display: none;
          &.hovered {
            display: block;
            visibility: visible !important;
            animation: fadeIn 0.3s ease-in-out;
          }
          @media all and (max-width:980px){
            display: block;
          }
  
        }
      div.imageContainer {
        width: 100%;
        display: block;
        position: relative;
        overflow: hidden;
       box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        img {
          width: 100%;
          display: block;
          transition: 0.2s ease-in-out;
          border-top-right-radius: 3px;
          border-top-left-radius: 3px;
        }  
      
        div.extraActions {
            visibility: visible;
          div.showCardOverlay {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: var(--overlay);
            z-index: 8;
          } 
        }
        &.hover{
         position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: var(--overlay);
        z-index: 8;
        } 
      }
      &.hover{
        position: absolute;
       top: 0;
       bottom: 0;
       right: 0;
       left: 0;
       background-color: var(--overlay);
       z-index: 8;
       }
     
      div.metaData {
        background: #181818;
        color: #fff;
        width: 100%;
        height: fit-content;
        padding: 8px 10px;
        box-sizing: border-box;
        visibility: hidden;
        transition: 0.9s ease-in-out;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        @media all and (max-width:980px){
          visibility: visible;
        }
        h1.title{
          font-size: 11px;
          color: var(--text-color);
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        h1.synopsis{
            font-size: 11px;
            color: var(--text-color);
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; 
        }
        div.buttons{
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
          margin-top: 10px;
          span.material-symbols-outlined{
            font-weight: 300 !important;
            font-size: 20px !important;
  
          }
          button{
            color:var( --white-color);
            background-color: var(--button-color);
            width: 50%;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            cursor: pointer;
            font-size: 10px;
            &:hover{
              background-color: var(--disabled-btn-color);
            }
          }
          div.left{
            display: flex;
            align-items: center;
          }
        }
      }
    }
    
  }
  