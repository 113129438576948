div.smallLoading{

    div.loading-container {
        // width: 256px;
        // height: 256px;
        position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%,-50%);
        z-index: 99999999;
    
      }
      
      .loading {
        // width: 64px;
        // height: 64px;
        position: absolute;
        // top: 36%;
        // left: 36%;
        transform: rotate(45deg);  
      }
      
      .loading div {
        width: 5px;
      height: 5px;
        position: absolute;
      }
      
      .l1 div, .l2 div, .l3 div, .l4 div {
        width: 100%;
        height: 100%;
        background-color: #3498db;
      }
      
      .l1 { transform: translate(0,0); }
      .l2 { transform: translate(0,5px); }
      .l3 { transform: translate(5px,0px); }
      .l4 { transform: translate(5px,5px); }
      
      @keyframes smallrot1 {
        0%{ transform: rotate(0deg); }
        40%{ transform: rotate(0deg); }
        50%{ transform: rotate(0deg); }
        60%{ transform: rotate(90deg); }
        100%{ transform: rotate(90deg); }
      }
      
      @keyframes smallrot2 {
        0%{ transform: rotate(0deg); }
        40%{ transform: rotate(0deg); }
        50%{ transform: rotate(0deg); }
        60%{ transform: rotate(-90deg); }
        100%{ transform: rotate(-90deg); }
      }
      
      @keyframes smalllrot3 {
        0%{ transform: rotate(45deg); }
        35%{ transform: rotate(45deg); }
        65%{ transform: rotate(405deg); }
        100%{ transform: rotate(405deg); }
      }
      
      @keyframes smalll1-rise {
        0%{ transform: translate(0px,0px); }
        30%{ transform: translate(-2px, -2px); }
        70%{ transform: translate(-2px, -2px); }
        100%{ transform: translate(0px, 0px); }
      }
      
      @keyframes smalll2-rise {
        0%{ transform: translate(0,5px); }
        30%{ transform: translate(-2px, 10px); }
        70%{ transform: translate(-2px, 10px); }
        100%{ transform: translate(0,5px); }
      }
      
      @keyframes smalll3-rise {
        0%{ transform: translate(5px, 0px); }
        30%{ transform: translate(10px, -2px); }
        70%{ transform: translate(10px, -2px); }
        100%{ transform: translate(5px, 0px); }
      }
      
      @keyframes smalll4-rise {
        0%{ transform: translate(5px,5px); }
        30%{ transform: translate(10px, 10px); }
        70%{ transform: translate(10px, 10px); }
        100%{ transform: translate(5px,5px); }
      }
      
      @keyframes color {
        0%{ background-color: #3498db; }
        50%{ background-color: #2980b9; }
        100%{ background-color: #3498db; }
      }
      
      .l1 { animation: smalll1-rise 1s ease 0s infinite; }
      .l2 { animation: smalll2-rise 1s ease 0s infinite; }
      .l3 { animation: smalll3-rise 1s ease 0s infinite; }
      .l4 { animation: smalll4-rise 1s ease 0s infinite; }
      
      .l1 div, .l4 div { animation: smallrot1 1s ease 0s infinite, color 1s linear 0s infinite; }
      .l2 div, .l3 div { animation: smallrot2 1s ease 0s infinite, color 1s linear 0s infinite; }
}