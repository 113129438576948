div.checkout-page {
    font-family: Avenir-Roman;
    padding: 100px 50px;
    background-color: var(--black-color);
    min-height: 90vh;
    color: #fff;
  }

  @media only screen and (max-width: 768px) {
    div.checkout-page {
      padding: 100px 15px;
    }
  }
  
  div.checkout-page span.heading {
    text-transform: none;
    color: #fff;
    letter-spacing: 0.5px;
    font-size: 40px;
  }
  
  @media only screen and (max-width: 768px) {
    div.checkout-page span.heading {
      font-size: 30px;
    }
  }
  
  div.checkout-page div.sign-in-link-section {
    margin-bottom: 30px;
    text-align: right;
    font-size: medium;
    font-weight: lighter;
  }
  
  @media only screen and (max-width: 768px) {
    div.checkout-page div.sign-in-link-section {
      text-align: left;
    }
  }
  
  div.checkout-page div.sign-in-link-section .sub-heading {
    margin-right: 10px;
    font-size: 15px;
    color: #fff;
  }
  
  div.checkout-page div.sign-in-link-section .signin-link {
    font-size: medium;
    cursor: pointer;
    color: #2dbebd;
  }
  
  div.checkout-page div.sign-in-link-section .signin-link:hover {
    color: #fff;
  }
  
  div.checkout-page div.wrapper {
    display: flex;
    justify-content: space-between;
  }
  
  @media only screen and (max-width: 768px) {
    div.checkout-page div.wrapper {
      flex-direction: column;
    }
  }
  
  div.checkout-page div.wrapper .left .user-section {
  }
  
  @media only screen and (min-width: 768px) {
    div.checkout-page div.wrapper .left .user-section {
      width: 485px;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    div.checkout-page div.wrapper .left .user-section {
      width: 420px;
      margin-right: 40px;
    }
  }
  
  div.checkout-page div.wrapper .left .user-section .forgot-link {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  div.checkout-page div.wrapper .left .user-section .forgot-link .forgot-text {
    margin-top: -5px;
  }
  
  div.checkout-page div.wrapper .left .user-section .login-with-email-password {
    text-align: center;
    margin: 20px 0px;
    background: #12121266;
    padding: 10px;
    border-radius: 2px;
  }
  
  div.checkout-page div.wrapper .left .user-section .login-with-email-wrapper {
    margin: 20px 0px;
  }
  
  div.checkout-page div.wrapper .left .user-section .login-with-email-wrapper button {
    padding: 10px;
    width: 100%;
    /* background: #2dbebd; */
    /* color: #121212; */
    border-radius: 2px;
    font-size: 17px;
  }
  
  div.checkout-page div.wrapper .left .user-section .login-with-email-wrapper button:hover {
    font-weight: 600;
    background: #cccccc17;
    color: #2dbebd;
  }
  
  div.checkout-page div.wrapper .left .user-section .seperate {
    display: flex;
    margin: 20px 0px;
  }
  
  div.checkout-page div.wrapper .left .user-section .seperate .line {
    width: 50%;
    background: #cccccc57;
    height: 1px;
    margin-top: 10px;
  }
  
  div.checkout-page div.wrapper .left .user-section .seperate .devider {
    margin: 0px 5px;
    color: rgba(204, 204, 204, 0.3411764706);
    font-size: medium;
  }
  
  div.checkout-page div.wrapper .left .user-section .existing-user-message {
    width: 485px;
    padding: 15px;
    background: #121212;
    margin: 20px 0px 0px 0px;
    border-radius: 2px;
    color: #fff;
  }
  
  @media only screen and (max-width: 768px) {
    div.checkout-page div.wrapper .left .user-section .existing-user-message {
      width: 100%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    div.checkout-page div.wrapper .left .user-section .existing-user-message {
      width: 420px;
    }
  }
  
  div.checkout-page div.wrapper .left .user-section .existing-user-message .close-message {
    font-size: x-small;
    border: 1px solid #ffffff82;
    padding: 0px 5px;
    border-radius: 50%;
    cursor: pointer;
    float: right;
    margin-top: -10px;
    margin-right: -10px;
    color: #ffffff82;
  }
  
  div.checkout-page div.wrapper .left .user-section .login-success-message {
    max-width: 485px;
    background: #121212;
    padding: 25px;
    border-radius: 2px;
    font-size: large;
    margin-top: 20px;
  }
  
  @media only screen and (max-width: 768px) {
    div.checkout-page div.wrapper .left .user-section .login-success-message {
      width: 100%;
      padding: 20px;
      font-size: medium;
    }
  }
  
  div.checkout-page div.wrapper .left .user-section .login-button {
    width: 100px;
    height: 35px;
    border-radius: 2px;
    font-size: 17px;
    /* background: #2dbebd; */
    /* color: #121212; */
    float: right;
  }
  
  div.checkout-page div.wrapper .left .user-section .login-button:hover {
    background: #cccccc17;
    color: #2dbebd;
  }
  
  div.checkout-page div.wrapper .left .user-section .password-section {
    margin-top: 25px;
  }
  
  div.checkout-page div.wrapper .left .user-section .password-section .password-show {
    float: right;
    font-weight: 600;
    cursor: pointer;
    color: #2dbebd;
    position: relative;
    top: 35px;
    right: 10px;
  }
  
  div.checkout-page div.wrapper .left .user-section .password-section .password-show:hover {
    color: #fff;
  }
  
  div.checkout-page div.wrapper .left .user-section input {
    width: 100%;
    height: 50px;
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 10px;
  }
  
  @media only screen and (min-width: 1024px) {
    div.checkout-page div.wrapper .left {
      width: 420px;
      margin-right: 40px;
    }
  }
  
  div.checkout-page div.wrapper .right {
    width: 60%;
    border-left: 2px solid #dddddd3d;
    padding-left: 70px;
  }
  
  @media only screen and (max-width: 768px) {
    div.checkout-page div.wrapper .right {
      border-left: transparent;
      border-top: 2px solid;
      width: 100%;
      margin-top: 20px;
      padding: 10px 0px;
    }
  }
  
  div.checkout-page div.wrapper .right span.heading {
    text-transform: none;
    color: #fff;
    letter-spacing: 0.5px;
    font-size: 30px;
  }
  
  @media only screen and (max-width: 768px) {
    div.checkout-page div.wrapper .right span.heading {
      font-size: 20px;
    }
  }
  
  div.checkout-page div.wrapper .right .package-section p.disclaimer-text {
    font-size: smaller;
    color: #fff;
  }
  
  div.checkout-page div.wrapper .right .package-section p.disclaimer-text span {
    font-size: small !important;
  }
  
  div.checkout-page div.wrapper .right .package-section .package-item {
    padding: 20px;
    background: #cccccc17;
    border-radius: 3px;
    margin: 10px 0px;
    width: 100%;
  }
  
  div.checkout-page div.wrapper .right .package-section .package-item .plan-description {
    color: #fff;
    max-height: 150px;
    overflow-y: scroll;
  }
  
  div.checkout-page div.wrapper .right .package-section .heading-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  div.checkout-page div.wrapper .right .package-section .heading-section .plan-options-wrapper {
    align-content: center;
    margin-top: 20px;
    cursor: pointer;
  }
  
  div.checkout-page div.wrapper .right .package-section .heading-section .plan-options {
    width: 20px;
    height: 20px;
    cursor: pointer !important;
  }
  
  div.checkout-page div.wrapper .right .package-section .heading-section .heading-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  div.checkout-page div.wrapper .right .package-section .heading-section .heading {
    font-size: 25px;
  }

  div.checkout-page div.wrapper .right .package-section .heading-section .sub-heading {
    font-size: 15px;
    color: #fff;
  }
  
  @media only screen and (max-width: 768px) {
    div.checkout-page div.wrapper .right .package-section .heading-section .heading {
      font-size: 15px;
    }
  }
  
  div.checkout-page div.wrapper .right .package-section .details {
    color: #2dbebd;
    cursor: pointer;
  }
  
  div.checkout-page div.wrapper .right .continue-button {
    width: 200px;
    height: 50px;
    border-radius: 2px;
    font-size: 20px;
    /* background: #2dbebd; */
    /* color: #121212; */
    float: right;
  }
  
  @media only screen and (max-width: 768px) {
    div.checkout-page div.wrapper .right .continue-button {
      width: 100%;
      height: 40px;
      font-size: 18px;
    }
  }
  
  div.checkout-page div.wrapper .right .continue-button:hover {
    background: #cccccc17;
    color: #2dbebd;
  }
  
  div.checkout-page .link {
    font-size: medium;
    cursor: pointer;
    color: #2dbebd;
  }
  
  div.checkout-page .link:hover {
    color: #fff;
  }
  
  div.checkout-page .white-link {
    cursor: pointer;
    color: #fff;
  }
  
  div.checkout-page .white-link:hover {
    color: #774ed6;
  }
  
  div.checkout-page .error {
    color: red;
  }

  
  div.checkout-page .success {
    color: #1bbf1b;
    margin-top: 5px;
}

  button{
    cursor: pointer;
  }

  .coupon-wrapper {
    display: flex;
    flex-direction: column;
}

.coupon-wrapper .coupon-link {
    display: flex;
    margin-bottom: 10px;
}

.coupon-wrapper .coupon-link .coupon-text {
    margin-top: 12px;
    margin-right: 10px;
}

.coupon-wrapper .coupon-link .coupon-check {
    width: 15px;
    cursor: pointer;
    margin-top: 12px;
    margin-right: 5px;
}

.coupon-wrapper .coupon-section input {
    width: 65%;
    margin-right: 5%;
    height: 40px;
    padding: 10px;
}

.coupon-wrapper .coupon-section button {
    width: 30%;
    height: 39px;
    border-radius: 2px;
    /* background: #2dbebd;
    color: #121212; */
}

.coupon-wrapper .coupon-section button:hover {
    background: #cccccc17;
    color: #2dbebd;
}

  