div.searchPage{
    padding: 100px 5px 40px;
    background-color: var(--secondary-bg-color);
    div.top{
        border-bottom: 1px solid var(--border-color);
        margin-left: 20px;
        @media all and (max-width:768px){
           margin-left: 10px;
        }
        h1.heading{
            font-size: 28px;
            margin-bottom: 10px;
            color: var(--heading-color);
            @media all and (max-width:640px){
                font-size: 36px;
            }
        }
        p.desc{
            font-size: 18px;
            color: var(--heading-color);
            @media all and (max-width:640px){
                font-size: 14px;
            }
        }
       
    }
    div.bottom{
        margin-top: 10px;
        div.categories {
            display: flex;
            margin-bottom: 20px;
            button {
                background-color: transparent;
                outline: none;
                color: var(--text-color);
                padding: 8px 30px;
                border-radius: 30px;
                font-size: 18px;
                margin-right: 10px;
                cursor: pointer;
                border:1px solid transparent;
                @media all and (max-width:1600px){
                    font-size: 15px;
                }
                @media all and (max-width:1200px){
                    font-size: 12px;
                }
                @media all and (max-width:768px){
                    padding: 8px 15px;
                }
                @media all and (max-width:640px){
                    padding: 8px 6px;
                }
                @media all and (max-width:480px){
                    padding: 8px 10px;
                    font-size: 10px;

                }
                @media all and (max-width:360px){
                    padding: 8px 8px;
                }
                &:last-child{
                    margin-right: 0;
                }
                &.active {
                border:1px solid var(--grey-color);
                background-color:var(--button-hover);
                color: var(--white-color);
                }
                &:hover {
                    background-color:var(--button-hover);
                    color: var(--white-color);
                }
            }
           
        }
        div.itemsContainer{
            display: flex;
            flex-wrap: wrap;
            div.items{
              &.rowCount3{
                  width: 32.5%;
                  margin-right: 13px;
                  margin-bottom: 30px;
                  &:nth-child(3n){
                      margin-right: 0;
                  }
                  @media all and (max-width:1200px){
                      width: 32%;
                  }
              }
              &.rowCount4{
                  width: 24%;
                  margin-right: 13px;
                  margin-bottom: 30px;
                  &:nth-child(4n){
                      margin-right: 0;
                  }
                  @media all and (max-width:1200px){
                      width: 23.8%;
                  }
              }
              &.rowCount5{
                  width: 19%;
                  margin-right: 13px;
                  margin-bottom: 30px;
                  &:nth-child(5n){
                      margin-right: 0;
                  }
                  @media all and (max-width:1200px){
                      width: 18.5%;
                  }
                  div.showCardContainer{
                    div.metaData{
                      h1.title{
                        font-size: 14px;
                      }
                    }
                  }
              }
              &.rowCount6{
                  width: 15.8%;
                  margin-right: 13px;
                  margin-bottom: 30px;
                  &:nth-child(6n){
                      margin-right: 0;
                  }
                  @media all and (max-width:1400px){
                      width: 15.5%;
                  }
                  @media all and (max-width:1200px){
                      width: 15%;
                  }
                  div.showCardContainer{
                    div.metaData{
                      h1.title{
                        font-size: 14px;
                      }
                    }
                  }
              }
              &.portrait{
                
                  // @media all and (max-width:1400px){
                  //     width: 15.6%;
                  // }
                  // @media all and (max-width:1200px){
                  //     width: 18.8%;
                  //     &:nth-child(5n){
                  //         margin-right: 0;
                  //     }
                  //    &:nth-child(6n){
                  //     margin-right: 13px;
                  //    }
                  // }
                  @media all and (max-width:980px){
                      width:23.5%;
                      margin-right: 13px;
                      margin-bottom: 40px;
                      &:nth-child(4n){
                          margin-right: 0;
                      }
                     &:nth-child(5n){
                      margin-right: 13px;
                     }
                  }
                  @media all and (max-width:768px){
                      width:23%;
                      &:nth-child(4n){
                          margin-right: 0;
                      }
                     &:nth-child(5n){
                      margin-right: 13px;
                     }
                  }
                  @media all and (max-width:640px){
                      width:31%;
                      
                     &:nth-child(4n){
                      margin-right: 13px;
                     }
                     &:nth-child(3n){
                      margin-right: 0;
                  }
                  }
                  @media all and (max-width:480px){
                      width:47%;
                      
                     &:nth-child(3n){
                      margin-right: 13px;
                     }
                     &:nth-child(2n){
                      margin-right: 0;
                  }
                  }
              }
              &.landscape{
                  // width: 32.8%;
             
              // &:nth-child(3n){
              //     margin-right: 0;
              // }
              // @media all and (max-width:1400px){
              //     width: 32.5%;
              // }
              // @media all and (max-width:1200px){
              //     width:32%;
              // //     &:nth-child(4n){
              // //         margin-right: 0;
              // //     }
              // //    &:nth-child(5n){
              // //     margin-right: 13px;
              // //    }
              // }
              @media all and (max-width:980px){
                  width:32%;
                  margin-right: 13px;
              margin-bottom: 40px;
                  &:nth-child(3n){
                      margin-right: 0;
                  }
                  
              }
              @media all and (max-width:768px){
                  width:47.6%;
                  &:nth-child(2n){
                      margin-right: 0;
                  }
                  &:nth-child(3n){
                      margin-right: 13px;
                  }
              }
              @media all and (max-width:640px){
                  width:48%;
                  
                 &:nth-child(3n){
                  margin-right: 13px;
                 }
                 &:nth-child(2n){
                  margin-right: 0;
              }
              }
              @media all and (max-width:480px){
                  width:47%;
                  
                 &:nth-child(3n){
                  margin-right: 13px;
                 }
                 &:nth-child(2n){
                  margin-right: 0;
              }
              }
              }

          }
        }
    }
}